import { STATES } from "@core/constants";
import { useUser } from "@core/services/nocd-api";
import { canadaStates } from "@features/intake/constants";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { isValid, parseISO } from "date-fns";
import { noop } from "lodash/fp";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";

import useCreateTransferRequest from "../hooks/useCreateTransferRequest";
import { TransferRequestCondition } from "../types";

const TRANSFER_REQUEST_REASON = [
  { value: "Member/Therapist Conflicting Availability" },
  { value: "Therapist Resignation/Leave" },
  { value: "Not a Good Fit (Therapist Match)" },
  { value: "Insurance/State Change" },
  { value: "Treatment for Specialty Condition" },
];

const COTHEARPY_REQUEST_REASON = [
  { value: "Member Traveling" },
  { value: "Support with other conditions" },
  { value: "Therapist availability" },
  { value: "Other" },
];

enum CoTherapyRequestReason {
  MEMBER_TRAVELING = "Member Traveling",
  SUPPORT_OTHER_CONDITIONS = "Support with other conditions",
  THERAPIST_AVAILABILITY = "Therapist availability",
  OTHER = "Other",
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "It varies",
];

const conditionOptions = [
  TransferRequestCondition.OCD,
  TransferRequestCondition.HOARDING_DISORDER,
  TransferRequestCondition.BFRBS,
  TransferRequestCondition.PTSD,
  TransferRequestCondition.BDD,
];

const createTransferRequestSchema = yup.object().shape({
  reason: yup.string().required("Required"),
  reason_details: yup.string().required("Required"),
  conditions: yup
    .array()
    .of(yup.string())
    .min(1, "At least one must be selected")
    .required("Required")
    .typeError("Required"),

  primary_clinician_state: yup
    .string()
    .nullable()
    .when("reason", {
      is: CoTherapyRequestReason.MEMBER_TRAVELING,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  secondary_clinician_state: yup
    .string()
    .nullable()
    .when("reason", {
      is: CoTherapyRequestReason.MEMBER_TRAVELING,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
  current_conditions: yup
    .array()
    .of(yup.string())
    .nullable()
    .when("reason", {
      is: CoTherapyRequestReason.SUPPORT_OTHER_CONDITIONS,
      then: (schema) =>
        schema
          .min(1, "At least one must be selected")
          .required("Required")
          .typeError("Required")
          .test(
            "no-overlap",
            "Current conditions must not overlap with requesting conditions",
            function (value) {
              const { conditions } = this.parent;
              if (!value || !conditions) return true;
              const overlap = value.some((condition) =>
                conditions.includes(condition)
              );
              return !overlap;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  primary_day_availability: yup
    .array()
    .of(yup.string())
    .nullable()
    .when("reason", {
      is: CoTherapyRequestReason.THERAPIST_AVAILABILITY,
      then: (schema) => schema.required("Required").typeError("Required"),
      otherwise: (schema) => schema.nullable(),
    }),
});

const CreateTransferOrCoTherapyRequestDialog = ({
  userId,
  isOpen,
  onClose,
  onSuccess = noop,
  isForDischargeNote,
}: {
  userId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: (result?: string) => void;
  isForDischargeNote?: boolean;
}) => {
  const formId = "create-transfer-co-therapy-request-form";
  const [requestType, setRequestType] = useState("Transfer");

  const isTransfer = Boolean(requestType === "Transfer");
  const reasons = isTransfer
    ? TRANSFER_REQUEST_REASON
    : COTHEARPY_REQUEST_REASON;

  const { mutateAsync: create } = useCreateTransferRequest();
  const { data: user } = useUser();

  const { handleSubmit, formState, register, control, setValue, reset, watch } =
    useForm({
      resolver: yupResolver(createTransferRequestSchema),
      defaultValues: {
        requestType: "Transfer",
        reason: "",
        reason_details: "",
        conditions: [] as string[],
        current_clinician_treatment_end_date: null as string | null,
        action: isTransfer ? "replace" : "add",
        primary_clinician_state: null as string | null,
        secondary_clinician_state: null as string | null,
        primary_day_availability: [] as string[],
        current_conditions: [] as string[],
      },
    });
  const { isSubmitting, errors } = formState;

  const handleRequestTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRequestType = event.target.value;
    setRequestType(newRequestType);
    setValue("requestType", newRequestType);

    reset({
      requestType: newRequestType,
      reason: "",
      reason_details: "",
      conditions: [],
      current_clinician_treatment_end_date: null,
      action: newRequestType === "Transfer" ? "replace" : "add",
      primary_clinician_state: null,
      secondary_clinician_state: null,
      primary_day_availability: [],
      current_conditions: [],
    });
  };
  const watchedReason = watch("reason");
  const onSubmit = handleSubmit((values) => {
    return toast.promise(
      create({
        user_id: userId,
        reason: values.reason,
        reason_details: values.reason_details,
        conditions: values.conditions,
        action: isTransfer ? "replace" : "add",
        is_current_clinician_continuing_treatment: false,
        current_clinician_treatment_end_date:
          values.current_clinician_treatment_end_date,
        primary_day_availability: values.primary_day_availability,
        secondary_clinician_state: values.secondary_clinician_state,
        primary_clinician_state: values.primary_clinician_state,
        current_conditions: values.current_conditions,
      })
        .then(() => onSuccess("success"))
        .then(onClose),
      {
        loading: "Creating request...",
        success: "Request created!",
        error: "Failed to create request",
      }
    );
  });

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>New Request</DialogTitle>
      <DialogContent>
        <form
          id={formId}
          onSubmit={(e) => {
            e.stopPropagation();
            return onSubmit(e);
          }}
        >
          {!isForDischargeNote ? (
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Is this a transfer or co-therapy request?
                <Tooltip
                  title={
                    <>
                      Selecting &apos;Transfer&apos; will REMOVE the member from
                      your caseload after they successfully transfer to another
                      clinician at NOCD.
                      <br />
                      Selecting &apos;Co-therapy&apos; will allow the member to
                      REMAIN on your caseload while they also see a second
                      therapist.
                    </>
                  }
                >
                  <InformationCircleIcon
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "8px",
                    }}
                  />
                </Tooltip>
              </FormLabel>
              <RadioGroup
                row
                value={requestType}
                onChange={handleRequestTypeChange}
              >
                <FormControlLabel
                  value="Transfer"
                  control={<Radio />}
                  label="Transfer"
                />
                <FormControlLabel
                  value="Co-therapy"
                  control={<Radio />}
                  label="Co-therapy"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          <Stack spacing={3} sx={{ mt: 2 }}>
            {isForDischargeNote && (
              <Alert severity="warning">
                <Typography fontWeight="bold">
                  There is no active transfer request for this members transfer
                  related discharge. Create one now to finish submitting this
                  discharge note.
                </Typography>
              </Alert>
            )}

            {isTransfer ? (
              <Alert severity="info">
                Please be sure the member is aware of this transfer BEFORE
                submitting the request.
              </Alert>
            ) : (
              <Alert severity="info">
                Please be sure the member is aware of this co-therapy request{" "}
                <br /> BEFORE submitting.
              </Alert>
            )}

            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="reason-label"
                    shrink
                    sx={{
                      backgroundColor: "white",
                      padding: "0 4px",
                    }}
                  >
                    <div>
                      Reason<span style={{ color: "red" }}>*</span>
                    </div>
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="reason-label"
                    error={!!errors.reason?.message}
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    {reasons.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors?.reason?.message && (
                    <FormHelperText error>
                      {errors?.reason?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            {watchedReason === CoTherapyRequestReason.MEMBER_TRAVELING ? (
              <>
                <Controller
                  name="primary_clinician_state"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="primary-clinician-state-label"
                        shrink
                        sx={{
                          backgroundColor: "white",
                          padding: "0 4px",
                        }}
                      >
                        <div>
                          State where YOU are treating member
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </InputLabel>
                      <Select
                        labelId="primary-clinician-state-label"
                        id="primary-clinician-state"
                        value={field.value || ""}
                        error={!!errors.primary_clinician_state?.message}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        label=""
                      >
                        {[...STATES, ...canadaStates].map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.primary_clinician_state?.message && (
                        <FormHelperText error>
                          {errors?.primary_clinician_state?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="secondary_clinician_state"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="secondary-clinician-state-label"
                        shrink
                        sx={{
                          backgroundColor: "white",
                          padding: "0 4px",
                        }}
                      >
                        <div>
                          {" "}
                          State where member is requesting co-therapy
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </InputLabel>

                      <Select
                        error={!!errors.secondary_clinician_state?.message}
                        labelId="secondary-clinician-state-label"
                        id="secondary-clinician-state"
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                        label="State where YOU are treating member"
                      >
                        {[...STATES, ...canadaStates].map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.secondary_clinician_state?.message && (
                        <FormHelperText error>
                          {errors?.secondary_clinician_state?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </>
            ) : null}

            {watchedReason === CoTherapyRequestReason.THERAPIST_AVAILABILITY ? (
              <>
                <Controller
                  name="primary_day_availability"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="primary-clinician-availability-label"
                        shrink
                        sx={{
                          backgroundColor: "white",
                          padding: "0 4px",
                        }}
                      >
                        <div>
                          When are YOU treating member
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </InputLabel>
                      <Select
                        multiple
                        labelId="primary-clinician-availability-label"
                        id="primary-clinician-availability"
                        value={field.value || []}
                        error={!!errors.primary_day_availability?.message}
                        onChange={(e) => {
                          const selectedValues = Array.isArray(e.target.value)
                            ? e.target.value
                            : [e.target.value];

                          if (selectedValues.includes("It varies")) {
                            // If "It varies" is selected, clear all other values
                            field.onChange(["It varies"]);
                          } else {
                            // Remove "It varies" if other values are selected
                            field.onChange(
                              selectedValues.filter(
                                (val) => val !== "It varies"
                              )
                            );
                          }
                        }}
                        label=""
                      >
                        {daysOfWeek.map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.primary_day_availability?.message && (
                        <FormHelperText error>
                          {errors?.primary_day_availability?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </>
            ) : null}

            {watchedReason ===
            CoTherapyRequestReason.SUPPORT_OTHER_CONDITIONS ? (
              <>
                <Controller
                  name="current_conditions"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="current-conditions-label"
                        shrink
                        sx={{
                          backgroundColor: "white",
                          padding: "0 4px",
                        }}
                      >
                        <div>
                          Condition YOU are treating member for
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </InputLabel>
                      <Select
                        labelId="current-conditions-label"
                        id="current_conditions"
                        value={field.value?.[0] || ""}
                        error={!!errors.current_conditions?.message}
                        onChange={(e) => field.onChange([e.target.value])}
                        label=""
                      >
                        {conditionOptions.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.current_conditions?.message && (
                        <FormHelperText error>
                          {errors?.current_conditions?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="conditions"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="request-conditions-label"
                        shrink
                        sx={{
                          backgroundColor: "white",
                          padding: "0 4px",
                        }}
                      >
                        <div>
                          Condition member is requesting co-therapy for
                          <span style={{ color: "red" }}>*</span>
                        </div>
                      </InputLabel>
                      <Select
                        labelId="request-conditions-label"
                        id="request_conditions"
                        value={field.value?.[0] || ""}
                        error={!!errors.conditions?.message}
                        onChange={(e) => field.onChange([e.target.value])}
                        label=""
                      >
                        {conditionOptions.map((condition) => (
                          <MenuItem key={condition} value={condition}>
                            {condition}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors?.conditions?.message && (
                        <FormHelperText error>
                          {errors?.conditions?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </>
            ) : null}

            <TextField
              label={
                <div>
                  Please provide more details
                  <span style={{ color: "red" }}>*</span>
                </div>
              }
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
              rows={3}
              error={!!errors.reason_details?.message}
              helperText={errors.reason_details?.message}
              {...register("reason_details")}
            />
            {isTransfer ? (
              <Controller
                control={control}
                name="current_clinician_treatment_end_date"
                render={({ field, fieldState }) => (
                  <DatePicker
                    label={
                      <div>
                        Anticipated treatment end date with{" "}
                        {user?.preferred_first_name}
                      </div>
                    }
                    value={parseISO(field.value)}
                    onChange={(e) => {
                      if (isValid(e)) {
                        return field.onChange(e.toISOString());
                      }

                      return field.onChange(undefined);
                    }}
                    slotProps={{
                      textField: {
                        error: Boolean(fieldState?.error?.message),
                        fullWidth: true,
                        helperText: fieldState?.error?.message,
                      },
                    }}
                  />
                )}
              />
            ) : null}
            {watchedReason !==
            CoTherapyRequestReason.SUPPORT_OTHER_CONDITIONS ? (
              <Controller
                control={control}
                name="conditions"
                render={({ field, fieldState }) => (
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                    error={!!fieldState?.error?.message}
                  >
                    <FormLabel component="legend">
                      <div>
                        {" "}
                        What is the member primarily seeking treatment for?
                        <span style={{ color: "red" }}>*</span>
                      </div>
                    </FormLabel>
                    <RadioGroup
                      aria-label="Living-situation"
                      name={field.name}
                      value={String(field.value)}
                    >
                      {conditionOptions.map((condition) => (
                        <FormControlLabel
                          key={condition}
                          control={
                            <Radio
                              checked={field.value.includes(condition)}
                              value={condition}
                              onChange={(e) => {
                                if (
                                  field.value.includes(
                                    e.target.value as TransferRequestCondition
                                  )
                                ) {
                                  field.onChange([]);
                                } else {
                                  field.onChange([e.target.value]);
                                }
                              }}
                            />
                          }
                          label={condition}
                        />
                      ))}
                    </RadioGroup>

                    {fieldState?.error?.message ? (
                      <FormHelperText error>
                        {fieldState.error.message}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                )}
              />
            ) : null}
          </Stack>
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" form={formId} disabled={isSubmitting}>
          Submit request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTransferOrCoTherapyRequestDialog;
